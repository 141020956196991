// Import Styles
import "./scss/style.scss";

// Import Components
import NavBar from "./components/NavBar";
import Header from "./components/Header";
import Footer from "./components/Footer";

// Import Pages
// import HomePage from "./components/pages/Home";

// Favicons
import appleTouch from './media/favicon/apple-touch-icon.png';
import favicon16 from './media/favicon/favicon-16x16.png';
import favicon32 from './media/favicon/favicon-32x32.png';
// import faviconIco from './media/favicon/favicon.ico';

document.querySelector('link[rel="apple-touch-icon"]').href = appleTouch;
document.querySelector('link[sizes="16x16"]').href = favicon16;
document.querySelector('link[sizes="32x32"]').href = favicon32;
// document.querySelector('link[type="image/x-icon"]').href = faviconIco;


// Render Navbar
NavBar.render();





// Function to handle navigation and update URL without reloading
function navigate(route) {
    history.pushState({}, '', route); // Updates the URL without reloading the page
    loadRoute(route); // Calls the function to load the correct content
}

// Function to load content based on the route
function loadRoute(route) {
    document.querySelector('main').innerHTML = '';

    const showcase = document.getElementById('showcase');
    showcase && document.querySelector('header').removeChild(showcase);
    const banner = document.getElementById('banner');
    banner && document.querySelector('header').removeChild(banner);

    if (route === '/home' || route === '/' || route === '/index') {
        // HomePage.render();
        import('./components/Header.js').then(module => {
            module.default.render();
        });
        import('./components/pages/Home.js').then(module => {
            module.default.render();
        });
        document.querySelector('#nav-bar a').classList.add('active');
    } else if (route === '/about') {
        import('./components/Banner.js').then(module => {
            module.default.render('About Zane Solicitors', 'about');
        });

        import('./components/pages/About.js').then(module => {
            module.default.render();
        });
        document.querySelector('#nav-bar a[href="/about"]').classList.add('active');
    } else if (route === '/lawyers') {
        import('./components/Banner.js').then(module => {
            module.default.render('Meet Our Lawyers', 'lawyers');
        });

        import('./components/pages/Lawyers.js').then(module => {
            module.default.render();
        });
        document.querySelector('#nav-bar a[href="/lawyers"]').classList.add('active');
    } else if (route === '/expertise') {
        import('./components/Banner.js').then(module => {
            module.default.render('Our Expertise', 'expertise');
        });

        import('./components/pages/Expertise.js').then(module => {
            module.default.render();
        });
        document.querySelector('#nav-bar a[href="/expertise"]').classList.add('active');
    } else if (route === '/contact') {
        import('./components/Banner.js').then(module => {
            module.default.render('Contact Us', 'contact');
        });
        import('./components/pages/Contact.js').then(module => {
            module.default.render();
        });
        document.querySelector('#nav-bar a[href="/contact"]').classList.add('active');
    } else {
        // HomePage.render();
        import('./components/Header.js').then(module => {
            module.default.render();
        });
        import('./components/pages/Home.js').then(module => {
            module.default.render();
        });
    }
}

// Initial route load when page loads
window.addEventListener('load', () => {
    loadRoute(window.location.pathname); // Load the current route based on the URL path
    
});

// Listen for popstate event to detect back and forward navigation
window.addEventListener('popstate', () => {
    loadRoute(window.location.pathname); // Reload content based on current path
});

// Example links for navigation
document
    .querySelectorAll('a[href="/home"]').forEach(nav =>
        nav.addEventListener('click', (e) => {
            e.preventDefault();
            navigate('/home');
        })
    );

document
    .querySelectorAll('a[href="/about"]')
    .forEach(nav =>
        nav.addEventListener('click', (e) => {
            e.preventDefault();
            navigate('/about');
        })
    );

document
    .querySelectorAll('a[href="/expertise"]')
    .forEach(nav =>
        nav.addEventListener('click', (e) => {
            e.preventDefault();
            navigate('/expertise');
        })
    );

document
    .querySelectorAll('a[href="/lawyers"]')
    .forEach(nav =>
        nav.addEventListener('click', (e) => {
            e.preventDefault();
            navigate('/lawyers');
        })
    );

document
    .querySelectorAll('a[href="/contact"]')
    .forEach(nav =>
        nav.addEventListener('click', (e) => {
            e.preventDefault();
            navigate('/contact');
        })
    );


// Render HTML
// Header.render();
// HomePage.render();
Footer.render();

