// Logo


class Header {
    constructor() {
        
        
    }
    addEventListeners() {
        this.cta.addEventListener('click', this.openCalendly.bind(this));
    }

    openCalendly() {
        window.open('http://www.calendly.com/zanesolicitors', '_blank').focus();
    }

    render() {
        const div = document.createElement('div');
        div.classList = 'align-center flex';
        div.id = 'showcase';
        div.innerHTML = `
            <div class="container">
                <h1 class="lg-heading mb-15">Modern legal solutions for Nigerian Businesses.</h1>
                <button class="btn btn-outline-light" id='cta'>Book a consultation</button>
            </div>
        `;

        document.querySelector('header').appendChild(div);
        this.cta = document.querySelector('#cta');
        this.addEventListeners();
    }


}


export default new Header;