class Footer {
    constructor() {

    }

    render() {
        document.querySelector('footer').innerHTML = `
        <div class="container py-1">
            <p class='text-center'>
                Copyright &copy; ${new Date().getFullYear()} &nbsp; | &nbsp; Zane Solicitors
            </p>
        </div>
        `;
    }
}

export default new Footer;