import logo from '../media/zane-logo.png';


class NavBar {
    constructor() {
        this._mobileNavBtn;
        this._mobileNav;
        this._mobileNavClose;
        this._nav;
    }


    _toggleMenu() {
        if (this._mobileNavBtn.classList.contains('fa-bars')) {
            this._mobileNavBtn.classList = 'fas fa-xmark';
            this._mobileNav.classList.remove('close');
            this._mobileNav.classList.add('open');
        } else {
            this._mobileNavBtn.classList = 'fas fa-bars';
            this._mobileNav.classList.remove('open');
            this._mobileNav.classList.add('close');
        }
    }

    _fixHeader() {
        if (window.scrollY > '400') {
            // console.log(window.scrollY);
            document.querySelector('#nav-bar').classList.add('fix-top');
            // this._mobileNav.style.zIndex = '10';
        }
        else if (window.scrollY < '400')
            document.querySelector('#nav-bar').classList.remove('fix-top')
    }

    render() {
        const div = document.createElement('div');
        div.classList = ' py-01';
        div.id = 'nav-bar';
        div.innerHTML = `
            <div class='container align-center flex space-between'>
            <img id="logo" src=${logo} alt="logo">
            
                <i class="fas fa-bars ${window.innerWidth > 599 && 'hide'}" id="mobile-nav-btn" ></i>

                <nav class="flex ${window.innerWidth < 600 && 'hide'}">
                    <a href="/home">Home</a>
                    <a href="/expertise">Expertise</a>
                    <a href="/about">About</a>
                    <a href="/contact">Contact</a>
                </nav>
            </div>

        `;



        // Create Mobile Menu Div
        const mobileNav = document.createElement('div');
        mobileNav.id = 'mobile-nav';
        mobileNav.classList = 'fixed top right close';
        mobileNav.innerHTML = `
                <i class="fas fa-xmark" id="mobile-nav-close" ></i>
                <nav class="flex flex-col">
                    <a href="/home">Home</a>
                    <a href="/expertise">Expertise</a>
                    <a href="/about">About</a>
                    <a href="/contact">Contact</a>
                </nav>`;


        //  Append Header in Header
        document.querySelector('header').appendChild(mobileNav);
        document.querySelector('header').appendChild(div);
        // Append Mobile Nav Menu in Document





        // Handles
        this._mobileNavBtn = document.querySelector('#mobile-nav-btn');

        this._mobileNavClose = document.querySelector('#mobile-nav-close');

        this._mobileNav = document.querySelector('#mobile-nav');

        this._nav = document.querySelector('nav');

        this._mobileNavLinks = document.querySelectorAll('#mobile-nav a');


        // Event Listeners
        this._mobileNavBtn.addEventListener('click', this._toggleMenu.bind(this))

        this._mobileNavClose.addEventListener('click', this._toggleMenu.bind(this))

        this._mobileNavLinks.forEach(link => {
            link.addEventListener('click', this._toggleMenu.bind(this))
        })

        window.addEventListener('scroll', this._fixHeader.bind(this))
    }
}

export default new NavBar;